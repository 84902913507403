import { forwardRef } from "@chakra-ui/react";
import { Button, ButtonProps } from "src/components/base/button";
import { IconInfo } from "src/components/icons";

const colorSchemeMap = {
    gray: {
        fill: "gray.100",
        color: "gray.800",
    },
    black: {
        fill: "gray.800",
        color: "white",
    },
    orange: {
        fill: "orange.200",
        color: "orange.600",
    },
    blush: {
        fill: "blush.200",
        color: "blush.700",
    },
};

export type ColorSchemeMap = keyof typeof colorSchemeMap;

interface IIconPopoverInfoProps extends Omit<ButtonProps, "colorScheme"> {
    colorScheme?: ColorSchemeMap;
    ariaLabel: string;
}

// this should be used for popovers, as it wraps a button around the icon
export const PopoverButtonIconInfo = forwardRef(({ colorScheme = "gray", ariaLabel, ...buttonProps }: IIconPopoverInfoProps, ref) => {
    return (
        <Button ref={ref} variant="unstyled" minW="auto" boxSize={2} aria-label={ariaLabel} {...buttonProps} display="block">
            <IconInfo display="block" boxSize={2} {...colorSchemeMap[colorScheme]} />
        </Button>
    );
});
