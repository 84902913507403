import { useMemo } from "react";
import { useRouter } from "next/router";
import { ILanguage } from "src/consts/locale";
import {
    formatDateShort,
    formatDateTimeWithWeekday,
    formatDateWithWeekday,
    formatMonthShort,
    formatMonthShortYear,
    formatTimeShort,
    formatWeekDayShortWithTimeShort,
    IFormat,
    IFormatDateShort,
} from "./date-time-formatter";

export interface IDateTimeFormatter {
    formatDateShort: (value: IFormatDateShort["value"]) => string;
    formatDateWithWeekday: (value: IFormat["value"]) => string;
    formatTimeShort: (value: IFormat["value"]) => string;
    formatDateTimeWithWeekday: (value: IFormat["value"]) => string;
    formatMonthShort: (value: IFormat["value"]) => string;
    formatMonthShortYear: (value: IFormat["value"]) => string;
    formatWeekDayShortWithTimeShort: (value: IFormat["value"]) => string;
}

export const useDateTimeFormatter = (): IDateTimeFormatter => {
    const router = useRouter();
    const language = router.locale as ILanguage;

    return useMemo(
        () => ({
            formatDateShort: value => formatDateShort({ value, language }),
            formatDateWithWeekday: value => formatDateWithWeekday({ value, language }),
            formatTimeShort: value => formatTimeShort({ value, language }),
            formatDateTimeWithWeekday: value => formatDateTimeWithWeekday({ value, language }),
            formatMonthShort: value => formatMonthShort({ value, language }),
            formatMonthShortYear: value => formatMonthShortYear({ value, language }),
            formatWeekDayShortWithTimeShort: value => formatWeekDayShortWithTimeShort({ value, language }),
        }),
        [language]
    );
};
