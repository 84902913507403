import { Box, BoxProps, HStack, StackProps, VStack } from "@chakra-ui/react";
import { IRankingValueSize } from "./ranking-value";
import { EnumRiskSeverity } from "./types";

const severities = [EnumRiskSeverity.highest, EnumRiskSeverity.high, EnumRiskSeverity.medium, EnumRiskSeverity.low, EnumRiskSeverity.lowest];

const colorMap = {
    [EnumRiskSeverity.lowest]: "green.600",
    [EnumRiskSeverity.low]: "pear.600",
    [EnumRiskSeverity.medium]: "yellow.600",
    [EnumRiskSeverity.high]: "orange.600",
    [EnumRiskSeverity.highest]: "red.600",
};

const barSizeMap: { [key in IRankingValueSize]: Pick<BoxProps, "w" | "h"> } = {
    xs: { w: 1, h: "2px" },
    sm: { w: 1, h: "3px" },
    md: { w: 1, h: "3px" },
    lg: { w: "12px", h: "5px" },
};

export interface IRiskFactorProps extends StackProps {
    severity: EnumRiskSeverity;
    size: IRankingValueSize;
}

export const RiskFactor = ({ severity, size, children, ...stackProps }: IRiskFactorProps) => (
    <HStack color={colorMap[severity]} spacing={0.5} {...stackProps}>
        <Box as="span" noOfLines={1} wordBreak="break-all">
            {children}
        </Box>
        <VStack align="stretch" spacing={size === "lg" ? "3px" : "2px"} aria-hidden={true}>
            {severities.map(itemSeverity => (
                <Box key={itemSeverity} {...barSizeMap[size]} bg={itemSeverity === severity ? "current" : "gray.100"} />
            ))}
        </VStack>
    </HStack>
);
