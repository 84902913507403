import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconOutlineStar: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <path
            d="m8.262 8.004-6.3982 0.88035-0.11097 0.021286c-0.75393 0.1872-1.0239 1.1574-0.43086 1.7044l4.596 4.2399-1.082 5.9717-0.013995 0.10922c-0.056609 0.7557 0.74018 1.3142 1.4434 0.96438l5.7346-2.8533 5.7346 2.8533 0.10099 0.043877c0.70999 0.26496 1.4684-0.34462 1.3284-1.1175l-1.083-5.9717 4.597-4.2399 0.078884-0.080894c0.51167-0.58459 0.17854-1.5349-0.62071-1.6448l-6.3992-0.88035-2.8503-5.4664c-0.37382-0.71678-1.3995-0.71678-1.7733 0l-2.8513 5.4664zm3.738-2.842 2.2033 4.2253 0.062094 0.10336c0.15828 0.23072 0.40752 0.38629 0.68835 0.4249l4.8102 0.6604-3.442 3.1764-0.087866 0.092052c-0.18977 0.226-0.27146 0.52662-0.21807 0.82126l0.82498 4.5503-4.3956-2.1859-0.12296-0.051273c-0.2508-0.085455-0.52742-0.068364-0.76789 0.051273l-4.3966 2.1859 0.82598-4.5503 0.014688-0.1264c0.015343-0.2947-0.10053-0.58387-0.32063-0.7869l-3.443-3.1764 4.8112-0.6604c0.32095-0.04413 0.60064-0.24101 0.75045-0.52826l2.2033-4.2253z"
            fill="currentColor"
        />
    </Icon>
);
