import {
    // eslint-disable-next-line no-restricted-imports
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverTrigger,
    PopoverProps,
    Flex,
    Portal,
    PopoverContentProps,
    PopoverArrowProps,
} from "@chakra-ui/react";
import { ArrowLink } from "src/components/base/arrow-link";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { IconClose } from "src/components/icons";
import { ILink } from "src/types/common";

export interface IWfPopoverProps extends PopoverProps {
    content: string;
    link?: ILink | null;
    contentProps?: PopoverContentProps;
    arrowProps?: PopoverArrowProps;
    children: React.ReactNode;
}

export const WfPopover = ({ children, content, link, placement = "bottom", contentProps, arrowProps, ...props }: IWfPopoverProps) => {
    const showCloseButton = props.trigger !== "hover";

    return (
        <Popover {...{ placement, ...props }} variant="responsive">
            <PopoverTrigger>{children}</PopoverTrigger>
            <Portal>
                <PopoverContent {...contentProps}>
                    <PopoverArrow {...arrowProps} />
                    {showCloseButton && (
                        <PopoverCloseButton top={1} right={1} width={4} height={4} padding={1}>
                            <IconClose boxSize={2} />
                        </PopoverCloseButton>
                    )}
                    <PopoverBody pr={showCloseButton ? 7 : 2}>
                        <Flex align="center" minH={4}>
                            <SimpleRichText text={content} />
                        </Flex>
                        {link && (
                            <ArrowLink display="inline-block" mt={2} href={link.url} target={link.target} colorScheme="green">
                                {link.name}
                            </ArrowLink>
                        )}
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    );
};
