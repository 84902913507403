import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconFilledStar: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <path
            d="m8.262 8.004-6.3982 0.88035-0.11097 0.021286c-0.75393 0.1872-1.0239 1.1574-0.43086 1.7044l4.596 4.2399-1.082 5.9717-0.013995 0.10922c-0.056609 0.7557 0.74018 1.3142 1.4434 0.96438l5.7346-2.8533 5.7346 2.8533 0.10099 0.043877c0.70999 0.26496 1.4684-0.34462 1.3284-1.1175l-1.083-5.9717 4.597-4.2399 0.078884-0.080894c0.51167-0.58459 0.17854-1.5349-0.62071-1.6448l-6.3992-0.88035-2.8503-5.4664c-0.37382-0.71678-1.3995-0.71678-1.7733 0l-2.8513 5.4664z"
            fill="currentColor"
        />
    </Icon>
);
