import { ILanguage } from "src/consts/locale";
import { getLocale } from "./shared";

const getMonthShort = (language: ILanguage) => {
    const locale = getLocale(language);
    return new Intl.DateTimeFormat(locale, { month: "short" });
};

const getShortDateFormatter = (language: ILanguage) => {
    const locale = getLocale(language);
    return new Intl.DateTimeFormat(locale, { year: "numeric", month: "2-digit", day: "2-digit" });
};

const dateFormatterCache = {} as Record<ILanguage, Intl.DateTimeFormat>;

const getDateFormatterWithWeekday = (language: ILanguage) => {
    let formatter = dateFormatterCache[language];

    if (!formatter) {
        const locale = getLocale(language);
        formatter = new Intl.DateTimeFormat(locale, {
            weekday: "long",
            day: "2-digit",
            month: "short",
            year: "numeric",
        });
        dateFormatterCache[language] = formatter;
    }

    return formatter;
};

const getMonthShortYear = (language: ILanguage) => {
    const locale = getLocale(language);

    return new Intl.DateTimeFormat(locale, { month: "short", year: "numeric" });
};

const getShortTimeFormatter = (language: ILanguage) => {
    const locale = getLocale(language);
    return new Intl.DateTimeFormat(locale, { hour: "2-digit", minute: "2-digit" });
};

const getShortWeekDayFormatter = (language: ILanguage) => {
    const locale = getLocale(language);
    return new Intl.DateTimeFormat(locale, { weekday: "short" });
};

/**
    Returns the date in the format depending on the locale:
    - German: DD.MM.YYYY
    - English: MM/DD/YYYY
*/
export interface IFormatDateShort {
    value: Date | number | string;
    language: ILanguage;
}

export const formatDateShort = ({ value, language }: IFormatDateShort) => {
    value = typeof value === "string" ? new Date(value) : value;
    return getShortDateFormatter(language).format(value);
};

export interface IFormat {
    value: Date | number;
    language: ILanguage;
}

/**
    Returns the date in the format depending on the locale:
    - German: Sonntag, 20. Dez. 2020
    - English: Sunday, Dec 20, 2020
*/
export const formatDateWithWeekday = ({ value, language }: IFormat) => {
    return getDateFormatterWithWeekday(language).format(value);
};

/**
    Returns the time in the format depending on the locale:
    - German: HH:mm
    - English: hh:mm A
*/
export const formatTimeShort = ({ value, language }: IFormat) => {
    return getShortTimeFormatter(language).format(value);
};

/**
 Returns the short week day with time in the format depending on the locale:
 - German: Mo, 03:00
 - English: Mo, 03:00 AM
 */
export const formatWeekDayShortWithTimeShort = ({ value, language }: IFormat) => {
    return `${getShortWeekDayFormatter(language).format(value)}, ${getShortTimeFormatter(language).format(value)}`;
};

/**
    Returns the date in the format depending on the locale:
    - German: Sonntag, 20. Dez. 2020 - 03:23
    - English: Sunday, Dec 20, 2020 - 03:23 AM
*/
export const formatDateTimeWithWeekday = ({ value, language }: IFormat) => {
    return `${formatDateWithWeekday({ value, language })} - ${formatTimeShort({ value, language })}`;
};

/**
    Returns the time in the format depending on the locale:
    - German: Apr
    - English: Apr
*/
export const formatMonthShort = ({ value, language }: IFormat) => {
    return getMonthShort(language).format(value);
};

/**
    Returns the time in the format depending on the locale:
    - German: Apr. 2020
    - English: Apr 2020
*/
export const formatMonthShortYear = ({ value, language }: IFormat) => {
    return getMonthShortYear(language).format(value);
};
