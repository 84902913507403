import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconHidden: TIconComponent = props => (
    <Icon viewBox="0 0 136 136" {...props}>
        <g fill="none" fillRule="evenodd" transform="translate(7 7)">
            <circle cx="61" cy="61" r="61" fill="#D5E6A5" />
            <path
                d="m95.224 62.742c0 18.844-15.276 34.12-34.12 34.12s-34.118-15.276-34.118-34.12c0-18.844 15.274-34.119 34.118-34.119s34.12 15.275 34.12 34.119"
                fill="#fff"
            />
            <path
                d="m93.234 58.911c0 18.844-15.276 34.12-34.12 34.12s-34.118-15.276-34.118-34.12 15.274-34.12 34.118-34.12 34.12 15.276 34.12 34.12z"
                stroke="#333"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
            />
            <path
                d="m78.457 53.359v0c-11.223 14.835-25.554 14.994-37.286 0"
                stroke="#333"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
            />
            <line x1="59.814" x2="59.814" y1="73.866" y2="64.545" stroke="#333" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
            <line x1="70.999" x2="76.593" y1="60.815" y2="68.273" stroke="#333" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
            <line x1="48.628" x2="43.035" y1="60.815" y2="68.273" stroke="#333" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
            <path
                d="m112 46 1.3771 3.8248c0.63622 1.7676 2.0292 3.1615 3.7986 3.7977l3.8238 1.3771-3.8238 1.3771c-1.7694 0.63715-3.1624 2.0301-3.7986 3.7986l-1.3771 3.8248-1.378-3.8248c-0.63622-1.7685-2.0282-3.1615-3.7977-3.7986l-3.8248-1.3771 3.8248-1.3771c1.7694-0.63622 3.1615-2.0301 3.7977-3.7977l1.378-3.8248z"
                stroke="#333"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
            />
            <path
                d="m22 4 1.378 3.8248c0.63622 1.7685 2.0282 3.1615 3.7977 3.7977l3.8248 1.378-3.8248 1.3761c-1.7695 0.63715-3.1615 2.0301-3.7977 3.7996l-1.378 3.8238-1.3771-3.8238c-0.63622-1.7695-2.0292-3.1624-3.7977-3.7996l-3.8248-1.3761 3.8248-1.378c1.7685-0.63622 3.1615-2.0292 3.7977-3.7977l1.3771-3.8248z"
                stroke="#333"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
            />
            <path
                d="m26 104 1.3779 3.8246c0.63618 1.7684 2.0281 3.1613 3.7975 3.7984l3.8246 1.377-3.8246 1.377c-1.7694 0.63712-3.1613 2.0291-3.7975 3.7984l-1.3779 3.8246-1.377-3.8246c-0.63712-1.7694-2.0291-3.1613-3.7984-3.7984l-3.8246-1.377 3.8246-1.377c1.7694-0.63712 3.1613-2.03 3.7984-3.7984l1.377-3.8246z"
                stroke="#333"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
            />
            <path
                d="m98.211 92.389c1.6238-1.5364 4.1745-1.5015 5.7556 0.078618l17.32 17.324c3.5931 3.5902 3.4495 9.503-0.42803 12.91-3.5397 3.1107-8.9624 2.7157-12.293-0.61535l-17.292-17.293c-1.6442-1.6432-1.6073-4.3181 0.081529-5.9148l6.8562-6.4894z"
                stroke="#333"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
            />
            <polygon
                points="79.367 86.679 91.458 98.771 97.758 92.471 85.667 80.379"
                stroke="#333"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
            />
        </g>
    </Icon>
);
